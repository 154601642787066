<div class="footer">
  <div class="footMail">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-8">
          <div class="footTopLft" *ngIf="isLoading">
            <ngx-skeleton-loader count="3" animation="progress"></ngx-skeleton-loader>
          </div>
          <div class="footTopLft" *ngIf="!isLoading">
            <h2>{{cmsFooterData?.newsletterHeading}}</h2>
            <p [innerHTML]="cmsFooterData?.newsletterText"></p>
          </div>
          <div class="footerMail">
            <input type="text" placeholder="Enter Email" [(ngModel)]="mailId">
            <button aria-label="sign" type="button" class="btn" (click)="subscribe()">Sign Up</button>
          </div>
        </div>
        <div class="col-lg-2  col-md-4 col-xs-6">
          <h3 class="ft_heading">QUICK LINKS</h3>
          <ul class="footLinks">
            <li><a (click)="hitHeaderMenu()" id="createNow">Fashion</a></li>
            <li><a routerLink='/why-custom' routerLinkActive="active">Why Custom</a></li>
            <li><a routerLink='/how-it-works' routerLinkActive="active">How It Works</a></li>
            <li><a routerLink='/perfect-fit-guarantee' routerLinkActive="active">Perfect Fit Guarantee</a></li>
            <li><a routerLink='/how-it-made' routerLinkActive="active">How It’s Made</a></li>
          </ul>
        </div>
        <div class="col-lg-3  col-md-6 col-xs-6 pl_30">
          <h3 class="ft_heading">OTHER LINKS</h3>
          <ul class="footLinks">
            <li><a routerLink="/what-is-shopdrop" routerLinkActive="active">What is ShopDrop?</a></li>
            <li><a routerLink='/our-fabrics' routerLinkActive="active">Our Fabrics</a></li>
            <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
            <li><a routerLink="/terms-and-conditions" routerLinkActive="active">Terms and Conditions</a></li>
            <li><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-lg-3  col-md-6 col-xs-6">
          <h3 class="ft_heading">CONTACT <span>US</span> <span class="xs_show">US</span></h3>
          <ul class="addressCallMail" *ngIf="isLoading">
            <li>
              <mat-icon> location_on</mat-icon>
              <span>
                <ngx-skeleton-loader count="1" animation="progress"></ngx-skeleton-loader>
              </span>
            </li>
            <li>
              <mat-icon>call</mat-icon>
              <span>
                <a [href]="'tel:' + footerData?.contact_number">
                  <ngx-skeleton-loader count="1" animation="progress"></ngx-skeleton-loader>
                </a>
              </span>
            </li>
            <li>
              <mat-icon>email</mat-icon>
              <span>
                <a [href]="'mailto:' + footerData?.support_email">
                  <ngx-skeleton-loader count="1" animation="progress"></ngx-skeleton-loader>
                </a>
              </span>
            </li>
          </ul>
          <ul class="addressCallMail" *ngIf="!isLoading">
            <li>
              <mat-icon> location_on</mat-icon>
              <span>
                {{footerData?.contact_address}}</span>
            </li>
            <li>
              <mat-icon>call</mat-icon>
              <span>
                <a [href]="'tel:' + footerData?.contact_number">{{footerData?.contact_number}}</a>
              </span>
            </li>
            <li>
              <mat-icon>email</mat-icon>
              <span>
                <a [href]="'mailto:' + footerData?.support_email">{{footerData?.support_email}}</a>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-lg-12 col-xs-6">
          <ul class="socialLink">
            <li><a [href]="footerData?.instagram_url" target="blank"> <i class="fa fa-instagram" aria-hidden="true"></i>
              </a></li>
            <li><a [href]="footerData?.facebook_url" target="blank"><i class="fa fa-facebook" aria-hidden="true"></i>
              </a></li>
            <li><a [href]="footerData?.twitter_url" target="blank"><i class="fa fa-twitter" aria-hidden="true"></i>
              </a></li>
            <li><a [href]="footerData?.youtube_url" target="blank"><i class="fa fa-youtube-play" aria-hidden="true"></i>
              </a></li>
          </ul>
        </div>
      </div>


    </div>
    <div class="backToTop">
      <button aria-label="arrow" type="button" (click)="onEdit()">
        <mat-icon>arrow_upward</mat-icon>
      </button>
    </div>
  </div>

  <div class="copyright">
    <p>©Copyright 2022- <a routerLink="/">Shopdrop</a>. All right reserved</p>
  </div>
</div>