import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerData: any;
  mailId: any;
  newsletterData: any;
  footerYoutubeLink: any;
  cmsFooterData: any;
  isLoading: boolean = true;
  constructor(
    private api: ApiService,
    private event: EventService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.event.headerFooterData.subscribe((res: any) => {
      if (res) {
        this.isLoading = false;
        this.footerData = res;
      }
    })
    this.event.headerFooterCmsData.subscribe((res: any) => {
      if (res) {
        this.isLoading = false;
        this.cmsFooterData = res;
      }
    })
  }



  subscribe() {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.mailId) {
      if (regex.test(String(this.mailId).toLowerCase())) {
        this.api.post(`pageaction/subscribenewsletter`, { email: this.mailId }).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.api.alert(resp.message, 'success');
            this.mailId = '';
          } else {
            this.api.alert(resp.message, 'warning');
          }
        }, err => {
          this.api.alert(err.message, 'error');
        });
      } else {
        this.api.alert('Invalid Email address', 'error');
      }
    } else {
      this.api.alert('Email address cannot be empty', 'error');
    }
  }

  hitHeaderMenu() {
    this.event.isHitHeaderMenu.next(true);
    if (window.innerWidth < 1199) {
      let menu = document.getElementById("sidemenu");
      if (menu) {
        menu.classList.remove('hide');
        menu.classList.add('fullMenu');
      }
    }
    this.onEdit();
  }

  onEdit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

  }

}
